<template>
  <div class="inventory__page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <redirect class="mb-5" title="Products" route="website.products" />
        <h1 class="page-title">Inventory</h1>
      </v-col>
      <v-col cols="12" class="elevation-form px-5 py-5">
        <Resource
          :document="document"
          :filter-fields="null"
          :filter-data="filterData"
          :filter-callback="setFilterData"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
          :update-quantity-callback="getUpdateQuantity"
          :actionItems="items"
          :callback-action="getAction"
          :routeName="updateRouteName"
        />
        <v-col cols="12" class="d-flex justify-space-between">
          <limit-page :callback="setLimit" />
          <pagination :length="lengthPage" :visible="0" :page.sync="page"></pagination>
        </v-col>
      </v-col>
    </v-row>
    <modal model="modal" title="Update quantity" :callback="updateQuantity">
      <template slot="content">
        <div class="d-flex align-center">
          <v-btn-toggle>
            <v-btn :color="action == 'add' ? '#BBDEFB' : ''" @click="action = 'add'" height="36">ADD </v-btn>
            <v-btn :color="action == 'set' ? '#BBDEFB' : ''" @click="action = 'set'" height="36">SET </v-btn>
          </v-btn-toggle>
          <v-text-field
            style="width: 70px"
            class="mt-0"
            width="50px"
            height="36"
            type="number"
            v-model="inventoryQuantity"
            hide-details
          ></v-text-field>
        </div>
        <div class="mt-5">
          <p>{{ variants.length }} variants will be changed:</p>
          <div class="d-flex" v-for="(variant, index) in variants" :key="index">
            <div>
              <p>{{ variant.product.title }}</p>
              <p>{{ variant.title }}</p>
            </div>
            <div class="ml-auto">
              {{ variant.inventoryQuantity ? variant.inventoryQuantity : 0 }}
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import { variantApi } from '@/apis/variants';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      inventoryQuantity: 0,
      action: 'add',
      resourceType: 'variants',
      modal: false,
      items: [
        { title: 'Continue selling when sold out', action: 'continue-selling' },
        { title: 'Stop selling when sold out', action: 'stop-selling' },
        { title: 'Update quantity', action: 'update-quantity' },
      ],
      tabs: [{ name: 'All', key: '' }],
      testVuex: null,
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },
        'Product Variant': {
          kind: 'product-variant',
          field: 'title',
          product: 'product',
        },
        SKU: {
          field: 'sku',
          kind: 'sku',
        },
        'WHEN SOLD OUT': {
          field: 'inventoryPolicy',
          kind: 'text-inventory',
        },
        QUANTITY: {
          kind: 'text',
          field: 'inventoryQuantity',
        },
        'UPDATE QUANTITY': {
          kind: 'update-quantity',
          field: '_id',
          data: {
            action: 'add',
            inventoryQuantity: 0,
          },
          style: 'text-align:right',
        },
      },
      updateRouteName: 'website.collections.update',
      searchableField: 'title_search',
      sortable: [],
      ids: [],
      variants: [],
    };
  },
  methods: {
    async getUpdateQuantity(data) {
      data.data.inventoryQuantity = parseInt(data.data.inventoryQuantity);
      try {
        await variantApi.updateInventory(data);
        this.getResources();
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },
    async updateQuantity() {
      let data = {};
      data.ids = this.ids;
      data.data = {};
      data.data.action = this.action;
      data.data.inventoryQuantity = parseInt(this.inventoryQuantity);
      try {
        await variantApi.updateInventory(data);
        this.getResources();
        this.$forceUpdate();

        this.modal = false;
        console.log('success');
      } catch (error) {
        console.log(error);

        this.modal = false;
      }
    },
    async getAction(action, ids) {
      // Action update quantity variant
      if (action.action == 'update-quantity') {
        this.ids = ids;
        this.variants = this.document.filter(variant => ids.includes(variant._id));
        this.modal = true;
      }
      // Action update policy variant continue
      if (action.action == 'continue-selling') {
        let data = {
          data: {},
        };
        data.ids = ids;
        data.data.inventoryPolicy = 'continue';
        try {
          await variantApi.updatePolicy(data);
          this.getResources();
          this.$forceUpdate();
        } catch (error) {}
      }
      // Action update policy deny
      if (action.action == 'stop-selling') {
        let data = {
          data: {},
        };
        data.ids = ids;
        data.data.inventoryPolicy = 'deny';
        try {
          await variantApi.updatePolicy(data);
          this.getResources();
          this.$forceUpdate();
        } catch (error) {}
      }
    },
  },
};
</script>
